import React from "react";
import Layout from "../../components/Layout";
import Meta from "../../components/Meta";
import * as styles from "../../styles/case-study.module.css";
import * as heroStyles from "../../styles/hero-section.module.css";
import Dart from "../../components/icons/Dart";
import Bulb from "../../components/icons/Bulb";
import Presentation from "../../components/icons/Presentation";
import LinearReveal from "../../components/animation/LinearReveal";
import HeroSection from "../../components/HeroSection";
import Card from "../../components/Card";

export default function CICDIntegration() {
  const csr = [
    {
      icon: <Dart />,
      title: "Challenge",
      description: (
        <p>
          Change Sets Limitations. <br />
          Difficulty Keeping Environments in Sync. <br />
          Lack of History Tracking. <br />
          Difficulty for Teams to Collaborate. <br />
          Limited Set of Quality Checks on Changes. <br />
          Lack of Monitoring and Compliance. <br />
          The Struggle of Migrating Data from Multiple Objects. <br />
          Difficulty in Identifying and Unblock Bottlenecks.
        </p>
      ),
    },
    {
      icon: <Bulb />,
      title: "Solution",
      description: (
        <p>
          Copado CI/CD provides more automation. <br />
          Back promotion helps in keeping environments in sync. <br />
          Integrates with Git. <br />
          Provide traceability.
          <br />
          No need to refresh sandbox as environments are in sync, so no data loss. <br />
          Provide Data migration using templates. <br />
          Pull Requests for code review process. <br />
          Quality Checks. <br />
          Very good user interface
        </p>
      ),
    },
    {
      icon: <Presentation />,
      title: "Results",
      description: (
        <p>
          Less Time in Deployment. <br />
          More Automation. <br />
          More Confidence.
          <br />
          More Traceability. <br />
          Almost no manual process in deployment.{" "}
        </p>
      ),
    },
  ];

  const challengeLimitations = [
    "Change sets do not offer the capability to search components by name or type",
    "Time consuming process, can not add/remove fields at a time",
    "Does not provide traceability for the requirement and changes",
    "Difficulty keeping environments in sync",
    "Need to refresh orgs frequently which causes data loss.",
    "Do not enable CI/CD",
  ];
  const challengeTool = [
    "Does not provide traceability",
    "Knowledge required on Command Line Utility",
    "Needed to refresh sandboxes to keep in sync that resulted data loss",
    "Need to use Data loader for migrating data",
    "Limited quality check on changes",
    "Developers are struggling with Git in remembering to rebase the code, resolve merge conflicts, merge to the right branch etc.",
  ];
  const solutionDesc = [
    "Copado is a Salesforce managed package and only need to install it in one Org, which will act as Governance Org. Copado will connect to other Salesforce orgs using an OAuth authentication access method.",
    "With Copado Continuous Delivery, automate quality testing and create connection behaviors with quality gates to ensure all your changes are passing the relevant quality checks while moving across the different environments in pipeline.",
    "Pipeline Wizard helps in defining pipelines at once with all the related environments.",
    "The Pipeline Manager is a user interface that allows you to orchestrate all your metadata changes through your environment landscape.",
    "In Copado, a user story is also used as a container object to develop, commit, promote, and deploy development work (metadata, Apex code, etc).",
    "Work with Git for version control. Eash promotion creates branches and merges the code automatically. Provides a very good graphical interface to create pull requests. This will free the developers from merge issues etc.",
    "Pipeline Manager Graphical Interface shows each environment status with ahead and behind stories list so that we can easily move the changes (Back Promotion) from environment to environment with a simple click.",
    "This implementation provided good traceability from the requirements (User Story) to all the changes traversing between environments.",
    "We can schedule automatic deployments during after office hours to have minimum impact",
    "Rollback is easier with Copado",
    "No need to create deployment scripts (package.xml) or destructiveChanges.xml and manual changes to production.",
    "Do not need frequent refreshes (or NONE if maintained correctly) as all the environments are being kept in sync.",
  ];

  return (
    <Layout>
      <Meta
        title="CI/CD Integration - DBT"
        description="Copado DevOps Platform (CI/CD) automates the Deployment process and more
            flexible compare to Change Set/Ant"
      />
      <HeroSection
        title="CI/CD Integration"
        titleClass={"text-primary " + heroStyles.heroTitle}
        imageClass={heroStyles.cicdIntegrationImage + " " + heroStyles.heroData}
        divClass={heroStyles.divClass}
      />
      <article className={"px-4 " + styles.article}>
        <LinearReveal>
          <h1 className={"text-primary has-text-centered px-0 " + styles.heading}>
            Copado DevOps Platform (CI/CD) automates the Deployment process and more
            flexible compare to Change Set/Ant
          </h1>{" "}
        </LinearReveal>
        <LinearReveal>
          <p className={styles.description}>
            One of our clients is using Change Sets/ Ant Migration Tool to deploy changes
            from one org to other related orgs. Change Set is more of a manual process and
            Ant Migration is not completely automatic. <br />
            See how Copado DevOps platform helps in more automation in CI/CD process.
          </p>
        </LinearReveal>
        <LinearReveal>
          <div className="is-flex is-justify-content-space-evenly is-flex-wrap-wrap mx-auto mt-4">
            {csr.map((card, index) => (
              <div key={index} className="is-flex is-justify-content-center m-4">
                <Card
                  icon={card.icon}
                  title={card.title}
                  titleClass="text-heading has-text-centered pb-0 mb-2"
                  cardStyle={{ width: "280px", paddingTop: "30px" }}
                  contentClass="text-description"
                  cardContentClass="px-4 pb-2"
                  content={card.description}
                />
              </div>
            ))}
          </div>
        </LinearReveal>
        <LinearReveal>
          <h2 className={"mt-5 pt-1 has-text-centered text-primary " + styles.title}>
            The Challenge
          </h2>
          <p className={styles.description}>
            One of our clients is using Change Sets and Ant Migration tools for deploying
            changes from one environment to another. This process is causing the following
            disadvantages -
          </p>
          <p className={styles.description}>Change Sets Limitations</p>
          <ul>
            {challengeLimitations.map((desc, i) => (
              <li key={i} className={styles.soultions}>
                {desc}
              </li>
            ))}
          </ul>
          <p className={styles.description}>Ant Migration Tool</p>
          <ul>
            {challengeTool.map((desc, i) => (
              <li key={i} className={styles.soultions}>
                {desc}
              </li>
            ))}
          </ul>
          <p className={styles.description}>
            With these limitations, the Deployment Manager spends lots of time on
            deployments with less confidence. It is difficult to track the changes going
            in the deployment. Removing a feature from the release is not a trivial task.
          </p>
        </LinearReveal>
        <LinearReveal>
          <h2 className={"mt-5 has-text-centered text-primary " + styles.title}>
            The Solution
          </h2>
          <p className={styles.description}>
            Salesforce CI/CD managed packages like Copado, Flowsum etc come in rescue. We
            have implemented Copado at our client place which provides pipeline
            configuration consisting of graphical flow of moving changes across
            environments.
          </p>
          <ul>
            {solutionDesc.map((desc, i) => (
              <li key={i} className={styles.soultions}>
                {desc}
              </li>
            ))}
          </ul>
          <p className={styles.description}>
            Any tool will not be foolproof with complete automation for all the scenarios.
            We defined the process in such a way that we make Copado more effective with
            our needs.
          </p>
        </LinearReveal>
        <LinearReveal>
          <h2 className={"mt-5 has-text-centered text-primary " + styles.title}>
            The Results
          </h2>
          <p className={styles.description}>
            After this implementation - Smooth deployments within a short span of time
            with more confidence. Back Promotion feature with graphical representation
            makes it easier to keep environments sync. Removing features from the planned
            release is easier now. Defining quality gates improves code quality and
            maintainability.
          </p>
        </LinearReveal>
      </article>
    </Layout>
  );
}
